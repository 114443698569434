/* Popover.css */
.popOver {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: rgb(44, 41, 56);
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 450px;
    min-height: fit-content;
    z-index: 10000;
}

.popOver1 {
    margin: 0 5px 5px 0;
    word-wrap: none;
    font-size: 17px;
    color: white;
}

.popOver2 {
    font-size: 14px;
    word-wrap: none;
    margin: 0 0 5px 0;
    color: white;
}

.popOver3 {
    font-size: 14px;
    word-wrap: none;
    margin: 5px 0 3px 0;
    color: white;
}

.textPopOver {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: rgb(44, 41, 56);
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    min-width: min-content;
    min-height: fit-content;
    z-index: 10000;
}
